.radio-input {
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  flex: 1 0 0;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
input[type="radio"] {
  margin-right: 8px;
  accent-color: #9766ffd4;
}
