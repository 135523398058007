.screen-design-right-side-bar {
  display: flex;
  flex-direction: column;
  flex: 0 0 380px;
  /* width: 380px; */
  height: 100%;
  flex-shrink: 0;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
  box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.25);

  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.screen-design-right-side-bar .sidebar-top-area {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 17px 17px;
}

.screen-design-right-side-bar .minimize-icon {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.screen-design-right-side-bar .variable-options-container {
  display: flex;
  /* width: 380px; */
  height: 32px;
  /* padding: 0px 8px; */
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  background: var(--Skaff-Color-Styles-Disable-Gray---Background, #f0eeee);
}

.screen-design-right-side-bar .variable-name-container {
  display: flex;
  width: 277px;
  height: 32px;
  padding: 2px 0px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.screen-design-right-side-bar .variable-name {
  width: 171px;
  flex-shrink: 0;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.screen-design-right-side-bar .variable-options {
  display: flex;
  padding: 0px 8px 0px 5px;
  align-items: flex-start;
  gap: 12px;
}

.screen-design-right-side-bar .navigation-bar {
  display: flex;
}

.screen-design-right-side-bar .nav-button {
  display: flex;
  height: 24px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  cursor: pointer;
}

.screen-design-right-side-bar .nav-button-selected {
  color: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  background: var(
    --Skaff-Color-Styles-Primary-Light,
    rgba(151, 102, 255, 0.83)
  );
}

.screen-design-right-side-bar .nav-button-text {
  /* 12 Pt Paragraph Comment Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

.screen-design-right-side-bar .variable-basic-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 12px 0px 12px;
}

.screen-design-right-side-bar .mandatory-field-container {
  display: flex;
  width: 368px;
  flex-direction: column;
  align-items: flex-start;
}

.screen-design-right-side-bar .field-container {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.screen-design-right-side-bar .checkbox-container {
  display: flex;
  width: 300px;
  padding: 0px 12px;
  align-items: center;
  gap: 12px;
}

.screen-design-right-side-bar .checkbox {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;

  border: 0.5px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.screen-design-right-side-bar .checkbox-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: right;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

.screen-design-right-side-bar .field-description-container {
  display: flex;
  height: 16px;
  padding: 4px 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.screen-design-right-side-bar .field-description-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 100% */
}

.screen-design-right-side-bar .select-variable-info {
  display: flex;
  padding-bottom: 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding-top: 17px;
}

.screen-design-right-side-bar .select-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-bottom: 0.5px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  background: var(
    --Skaff-Color-Styles-Primary-Light,
    rgba(151, 102, 255, 0.83)
  );
}

.screen-design-right-side-bar .select-header-text {
  display: flex;
  height: 24px;
  align-items: center;
  align-self: stretch;
  padding: 2px 12px;

  /* 12 Pt Paragraph Comment Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */

  color: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.screen-design-right-side-bar .select-contents-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.screen-design-right-side-bar .select-type-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.screen-design-right-side-bar .select-type {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.screen-design-right-side-bar .select-description-container {
  display: flex;
  height: 16px;
  padding: 4px 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.screen-design-right-side-bar .select-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 100% */
}

.options-table-container {
  overflow: auto;
  width: 100%;
}

.options-table-container table {
  width: 100%;
  border-collapse: collapse;
  color: var(--Skaff-Color-Styles-System-White, #f9f6ff);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.options-table-container thead {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.options-table-container th {
  padding: 4px 10px;
  border-left: 0.5px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  background: var(
    --Skaff-Color-Styles-Primary-Light,
    rgba(151, 102, 255, 0.83)
  );
}

.options-table-container .th-label-container {
  display: flex;
  height: 24px;
  padding: 4px 0px 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.options-table-container .th-label {
  flex: 1 0 0;
}

.options-table-container .th-label-icons {
  display: flex;
  height: 24px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.options-table-container tbody {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.options-table-container td {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  text-align: left;
  padding: 4px 10px;
  border-bottom: 0.5px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  border-left: 0.5px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
}

.options-table-container .td-input {
  outline: none;
  border: none;
  background: inherit;
  font: inherit;
}

.options-table-container th:first-child {
  border-left: none;
}
.options-table-container td:first-child {
  border-left: none;
  text-align: center;
}

.options-table-container tr {
  height: 24px;
}

.options-table-container .resizer {
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.options-table-container .resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .options-table-container .resizer {
    opacity: 0;
  }

  .options-table-container *:hover > .resizer {
    opacity: 1;
  }
}

.options-table-container .selected-row {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.instruction-expand-button-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.logics-modal {
  display: flex;
  flex-direction: column;
  width: 880px;
  flex-shrink: 0;
}

.logics-modal-header {
  display: flex;
  justify-content: space-between;

  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.header-close-button {
  display: flex;
  padding: 6px 6px 6px 0px;
  align-items: flex-start;
}

.logics-modal-contents {
  display: flex;
  flex-direction: column;
}
