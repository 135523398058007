.button {
  display: flex;
  height: 32px;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 0px 20px 0px 0px;
  border-bottom: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);

  cursor: pointer;
}

.button.selected {
  border-radius: 0px 20px 0px 0px;
  border-bottom: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
  box-shadow: 12px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.button:hover {
  border-radius: 0px 20px 0px 0px;
  border-bottom: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
  box-shadow: 12px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.button-icon {
  width: 15px;
  height: 15px;
}

.button-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
