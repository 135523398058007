.grids-nav-bar {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 0px 40px;
  justify-content: center;
  align-items: flex-start;
  /* gap: 300px; */
  flex-shrink: 0;

  border-bottom: 1px solid var(--Skaff-Color-Styles-Disable-Gra---Text, #838080);
  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.grids-nav-bar .nav-bar-left-side {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  gap: 6px;
}

.grids-nav-bar .nav-bar-icon {
  width: 32px;
  height: 32px;
}

.grids-nav-bar .nav-bar-right-side {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  gap: 20px;
}

.grids-container {
  display: flex;
  /* width: 868px; */
  padding-bottom: 4px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 8px;

  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}

.column-header {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.single-column-header {
  display: flex;
  height: 32px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-bottom: 1px solid
    var(--Skaff-Color-Styles-Primary-Light, rgba(151, 102, 255, 0.83));
  border-left: 1px solid
    var(--Skaff-Color-Styles-Primary-Light, rgba(151, 102, 255, 0.83));
  border-right: 1px solid
    var(--Skaff-Color-Styles-Primary-Light, rgba(151, 102, 255, 0.83));
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.column-header-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: right;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.grids-screen-item {
  display: flex;
  align-items: center;

  width: 100%;
  height: 32px;
  padding: 2px 12px;
  border: 1px solid var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: right;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  cursor: move;
}

.containerS {
  display: flex;
  gap: 6px;
}

.containerL {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;

  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}
