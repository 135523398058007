.home-main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.home-main-wrapper .content-wrapper {
  display: flex;
  flex: 1 1 auto;
}

.home-main-wrapper .interview-panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* background: var(--Skaff-Color-Styles-System-White, #f9f6ff); */
}

.home-main-wrapper .admin-page-interview-card {
  left: 220px !important;
  position: absolute !important;
  top: 239px !important;
}

.home-main-wrapper .admin-page-contact-settings-card {
  left: 220px !important;
  position: absolute !important;
  top: 423px !important;
}

.home-main-wrapper .admin-page-user-group-card {
  left: 220px !important;
  position: absolute !important;
  top: 607px !important;
}
