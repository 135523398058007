.divider-wrapper {
  display: flex;
  height: 0.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.divider {
  height: 0.5px;
  background: #cbd5e2;
  width: 100%;
}
