:root {
  --16-pt-paragraph-text-font-family: "Montserrat", Helvetica;
  --16-pt-paragraph-text-font-size: 16px;
  --16-pt-paragraph-text-font-style: normal;
  --16-pt-paragraph-text-font-weight: 500;
  --16-pt-paragraph-text-letter-spacing: 0px;
  --16-pt-paragraph-text-line-height: 24px;
  --18-pt-h4-heading-4-font-family: "Montserrat", Helvetica;
  --18-pt-h4-heading-4-font-size: 18px;
  --18-pt-h4-heading-4-font-style: normal;
  --18-pt-h4-heading-4-font-weight: 500;
  --18-pt-h4-heading-4-letter-spacing: 0px;
  --18-pt-h4-heading-4-line-height: 24px;
  --24-pt-h3-heading-3-font-family: "Montserrat", Helvetica;
  --24-pt-h3-heading-3-font-size: 24px;
  --24-pt-h3-heading-3-font-style: normal;
  --24-pt-h3-heading-3-font-weight: 500;
  --24-pt-h3-heading-3-letter-spacing: 0px;
  --24-pt-h3-heading-3-line-height: 24px;
  --32-pt-h2-heading-2-font-family: "Montserrat", Helvetica;
  --32-pt-h2-heading-2-font-size: 32px;
  --32-pt-h2-heading-2-font-style: normal;
  --32-pt-h2-heading-2-font-weight: 500;
  --32-pt-h2-heading-2-letter-spacing: 0px;
  --32-pt-h2-heading-2-line-height: 24px;
  --48pt-h1-heading-2-font-family: "Montserrat", Helvetica;
  --48pt-h1-heading-2-font-size: 48px;
  --48pt-h1-heading-2-font-style: normal;
  --48pt-h1-heading-2-font-weight: 500;
  --48pt-h1-heading-2-letter-spacing: 0px;
  --48pt-h1-heading-2-line-height: 24px;
  --72pt-h0-heading-0-font-family: "Montserrat", Helvetica;
  --72pt-h0-heading-0-font-size: 72px;
  --72pt-h0-heading-0-font-style: normal;
  --72pt-h0-heading-0-font-weight: 500;
  --72pt-h0-heading-0-letter-spacing: 0px;
  --72pt-h0-heading-0-line-height: 24px;
  --amber-100: rgba(254, 243, 199, 1);
  --amber-200: rgba(253, 230, 138, 1);
  --amber-300: rgba(252, 211, 77, 1);
  --amber-400: rgba(251, 191, 36, 1);
  --amber-50: rgba(255, 251, 235, 1);
  --amber-500: rgba(245, 158, 11, 1);
  --amber-600: rgba(217, 119, 6, 1);
  --amber-700: rgba(180, 83, 9, 1);
  --amber-800: rgba(146, 64, 14, 1);
  --amber-900: rgba(120, 53, 15, 1);
  --blue-100: rgba(219, 234, 254, 1);
  --blue-200: rgba(191, 219, 254, 1);
  --blue-300: rgba(147, 197, 253, 1);
  --blue-400: rgba(96, 165, 250, 1);
  --blue-50: rgba(239, 246, 255, 1);
  --blue-500: rgba(59, 130, 246, 1);
  --blue-600: rgba(37, 99, 235, 1);
  --blue-700: rgba(29, 78, 216, 1);
  --blue-800: rgba(30, 64, 175, 1);
  --blue-900: rgba(30, 58, 138, 1);
  --bold-16-pt-paragraph-text-font-family: "Montserrat", Helvetica;
  --bold-16-pt-paragraph-text-font-size: 16px;
  --bold-16-pt-paragraph-text-font-style: normal;
  --bold-16-pt-paragraph-text-font-weight: 800;
  --bold-16-pt-paragraph-text-letter-spacing: 0px;
  --bold-16-pt-paragraph-text-line-height: 24px;
  --bold-18-pt-h4-heading-4-font-family: "Montserrat", Helvetica;
  --bold-18-pt-h4-heading-4-font-size: 18px;
  --bold-18-pt-h4-heading-4-font-style: normal;
  --bold-18-pt-h4-heading-4-font-weight: 800;
  --bold-18-pt-h4-heading-4-letter-spacing: 0px;
  --bold-18-pt-h4-heading-4-line-height: 24px;
  --bold-24-pt-h3-heading-3-font-family: "Montserrat", Helvetica;
  --bold-24-pt-h3-heading-3-font-size: 24px;
  --bold-24-pt-h3-heading-3-font-style: normal;
  --bold-24-pt-h3-heading-3-font-weight: 800;
  --bold-24-pt-h3-heading-3-letter-spacing: 0px;
  --bold-24-pt-h3-heading-3-line-height: 24px;
  --bold-32-pt-h2-heading-2-font-family: "Montserrat", Helvetica;
  --bold-32-pt-h2-heading-2-font-size: 32px;
  --bold-32-pt-h2-heading-2-font-style: normal;
  --bold-32-pt-h2-heading-2-font-weight: 800;
  --bold-32-pt-h2-heading-2-letter-spacing: 0px;
  --bold-32-pt-h2-heading-2-line-height: 24px;
  --bold-48pt-h1-heading-2-font-family: "Montserrat", Helvetica;
  --bold-48pt-h1-heading-2-font-size: 48px;
  --bold-48pt-h1-heading-2-font-style: normal;
  --bold-48pt-h1-heading-2-font-weight: 800;
  --bold-48pt-h1-heading-2-letter-spacing: 0px;
  --bold-48pt-h1-heading-2-line-height: 24px;
  --bold-72pt-h0-heading-0-font-family: "Montserrat", Helvetica;
  --bold-72pt-h0-heading-0-font-size: 72px;
  --bold-72pt-h0-heading-0-font-style: normal;
  --bold-72pt-h0-heading-0-font-weight: 800;
  --bold-72pt-h0-heading-0-letter-spacing: 0px;
  --bold-72pt-h0-heading-0-line-height: 24px;
  --cyan-100: rgba(207, 250, 254, 1);
  --cyan-200: rgba(165, 243, 252, 1);
  --cyan-300: rgba(103, 232, 249, 1);
  --cyan-400: rgba(34, 211, 238, 1);
  --cyan-50: rgba(236, 254, 255, 1);
  --cyan-500: rgba(6, 182, 212, 1);
  --cyan-600: rgba(8, 145, 178, 1);
  --cyan-700: rgba(14, 116, 144, 1);
  --cyan-800: rgba(21, 94, 117, 1);
  --cyan-900: rgba(22, 78, 99, 1);
  --emerald-100: rgba(209, 250, 229, 1);
  --emerald-200: rgba(167, 243, 208, 1);
  --emerald-300: rgba(110, 231, 183, 1);
  --emerald-400: rgba(52, 211, 153, 1);
  --emerald-50: rgba(236, 253, 245, 1);
  --emerald-500: rgba(16, 185, 129, 1);
  --emerald-600: rgba(5, 150, 105, 1);
  --emerald-700: rgba(4, 120, 87, 1);
  --emerald-800: rgba(6, 95, 70, 1);
  --emerald-900: rgba(6, 78, 59, 1);
  --fuchsia-100: rgba(250, 232, 255, 1);
  --fuchsia-200: rgba(245, 208, 254, 1);
  --fuchsia-300: rgba(240, 171, 252, 1);
  --fuchsia-400: rgba(232, 121, 249, 1);
  --fuchsia-50: rgba(253, 244, 255, 1);
  --fuchsia-500: rgba(217, 70, 239, 1);
  --fuchsia-600: rgba(192, 38, 211, 1);
  --fuchsia-700: rgba(162, 28, 175, 1);
  --fuchsia-800: rgba(134, 25, 143, 1);
  --fuchsia-900: rgba(112, 26, 117, 1);
  --gray-100: rgba(243, 244, 246, 1);
  --gray-200: rgba(229, 231, 235, 1);
  --gray-300: rgba(209, 213, 219, 1);
  --gray-400: rgba(156, 163, 175, 1);
  --gray-50: rgba(249, 250, 251, 1);
  --gray-500: rgba(107, 114, 128, 1);
  --gray-600: rgba(75, 85, 99, 1);
  --gray-700: rgba(55, 65, 81, 1);
  --gray-800: rgba(31, 41, 55, 1);
  --gray-900: rgba(17, 24, 39, 1);
  --green-100: rgba(220, 252, 231, 1);
  --green-200: rgba(187, 247, 208, 1);
  --green-300: rgba(134, 239, 172, 1);
  --green-400: rgba(74, 222, 128, 1);
  --green-50: rgba(240, 253, 244, 1);
  --green-500: rgba(34, 197, 94, 1);
  --green-600: rgba(22, 163, 74, 1);
  --green-700: rgba(21, 128, 61, 1);
  --green-800: rgba(22, 101, 52, 1);
  --green-900: rgba(20, 83, 45, 1);
  --i-16-pt-paragraph-text-font-family: "Montserrat", Helvetica;
  --i-16-pt-paragraph-text-font-size: 16px;
  --i-16-pt-paragraph-text-font-style: italic;
  --i-16-pt-paragraph-text-font-weight: 500;
  --i-16-pt-paragraph-text-letter-spacing: 0px;
  --i-16-pt-paragraph-text-line-height: 24px;
  --i-18-pt-h4-heading-4-font-family: "Montserrat", Helvetica;
  --i-18-pt-h4-heading-4-font-size: 18px;
  --i-18-pt-h4-heading-4-font-style: italic;
  --i-18-pt-h4-heading-4-font-weight: 500;
  --i-18-pt-h4-heading-4-letter-spacing: 0px;
  --i-18-pt-h4-heading-4-line-height: 24px;
  --i-24-pt-h3-heading-3-font-family: "Montserrat", Helvetica;
  --i-24-pt-h3-heading-3-font-size: 24px;
  --i-24-pt-h3-heading-3-font-style: italic;
  --i-24-pt-h3-heading-3-font-weight: 500;
  --i-24-pt-h3-heading-3-letter-spacing: 0px;
  --i-24-pt-h3-heading-3-line-height: 24px;
  --i-32-pt-h2-heading-2-font-family: "Montserrat", Helvetica;
  --i-32-pt-h2-heading-2-font-size: 32px;
  --i-32-pt-h2-heading-2-font-style: italic;
  --i-32-pt-h2-heading-2-font-weight: 500;
  --i-32-pt-h2-heading-2-letter-spacing: 0px;
  --i-32-pt-h2-heading-2-line-height: 24px;
  --i-48pt-h1-heading-2-font-family: "Montserrat", Helvetica;
  --i-48pt-h1-heading-2-font-size: 48px;
  --i-48pt-h1-heading-2-font-style: italic;
  --i-48pt-h1-heading-2-font-weight: 500;
  --i-48pt-h1-heading-2-letter-spacing: 0px;
  --i-48pt-h1-heading-2-line-height: 24px;
  --i-72pt-h0-heading-0-font-family: "Montserrat", Helvetica;
  --i-72pt-h0-heading-0-font-size: 72px;
  --i-72pt-h0-heading-0-font-style: italic;
  --i-72pt-h0-heading-0-font-weight: 500;
  --i-72pt-h0-heading-0-letter-spacing: 0px;
  --i-72pt-h0-heading-0-line-height: 24px;
  --indigo-100: rgba(224, 231, 255, 1);
  --indigo-200: rgba(199, 210, 254, 1);
  --indigo-300: rgba(165, 180, 252, 1);
  --indigo-400: rgba(129, 140, 248, 1);
  --indigo-50: rgba(238, 242, 255, 1);
  --indigo-500: rgba(99, 102, 241, 1);
  --indigo-600: rgba(79, 70, 229, 1);
  --indigo-700: rgba(67, 56, 202, 1);
  --indigo-800: rgba(55, 48, 163, 1);
  --indigo-900: rgba(49, 46, 129, 1);
  --lime-100: rgba(236, 252, 203, 1);
  --lime-200: rgba(217, 249, 157, 1);
  --lime-300: rgba(190, 242, 100, 1);
  --lime-400: rgba(163, 230, 53, 1);
  --lime-50: rgba(247, 254, 231, 1);
  --lime-500: rgba(132, 204, 22, 1);
  --lime-600: rgba(101, 163, 13, 1);
  --lime-700: rgba(77, 124, 15, 1);
  --lime-800: rgba(63, 98, 18, 1);
  --lime-900: rgba(54, 83, 20, 1);
  --neutral-100: rgba(245, 245, 245, 1);
  --neutral-200: rgba(229, 229, 229, 1);
  --neutral-300: rgba(212, 212, 212, 1);
  --neutral-400: rgba(163, 163, 163, 1);
  --neutral-50: rgba(250, 250, 250, 1);
  --neutral-500: rgba(115, 115, 115, 1);
  --neutral-600: rgba(82, 82, 82, 1);
  --neutral-700: rgba(64, 64, 64, 1);
  --neutral-800: rgba(38, 38, 38, 1);
  --neutral-900: rgba(23, 23, 23, 1);
  --orange-100: rgba(255, 237, 213, 1);
  --orange-200: rgba(254, 215, 170, 1);
  --orange-300: rgba(253, 186, 116, 1);
  --orange-400: rgba(251, 146, 60, 1);
  --orange-50: rgba(255, 247, 237, 1);
  --orange-500: rgba(249, 115, 22, 1);
  --orange-600: rgba(234, 88, 12, 1);
  --orange-700: rgba(194, 65, 12, 1);
  --orange-800: rgba(154, 52, 18, 1);
  --orange-900: rgba(124, 45, 18, 1);
  --pink-100: rgba(252, 231, 243, 1);
  --pink-200: rgba(251, 207, 232, 1);
  --pink-300: rgba(249, 168, 212, 1);
  --pink-400: rgba(244, 114, 182, 1);
  --pink-50: rgba(253, 242, 248, 1);
  --pink-500: rgba(236, 72, 153, 1);
  --pink-600: rgba(219, 39, 119, 1);
  --pink-700: rgba(190, 24, 93, 1);
  --pink-800: rgba(157, 23, 77, 1);
  --pink-900: rgba(131, 24, 67, 1);
  --purple-100: rgba(243, 232, 255, 1);
  --purple-200: rgba(233, 213, 255, 1);
  --purple-300: rgba(216, 180, 254, 1);
  --purple-400: rgba(192, 132, 252, 1);
  --purple-50: rgba(250, 245, 255, 1);
  --purple-500: rgba(168, 85, 247, 1);
  --purple-600: rgba(147, 51, 234, 1);
  --purple-700: rgba(126, 34, 206, 1);
  --purple-800: rgba(107, 33, 168, 1);
  --purple-900: rgba(88, 28, 135, 1);
  --red-100: rgba(254, 226, 226, 1);
  --red-200: rgba(254, 202, 202, 1);
  --red-300: rgba(252, 165, 165, 1);
  --red-400: rgba(248, 113, 113, 1);
  --red-50: rgba(254, 242, 242, 1);
  --red-500: rgba(239, 68, 68, 1);
  --red-600: rgba(220, 38, 38, 1);
  --red-700: rgba(185, 28, 28, 1);
  --red-800: rgba(153, 27, 27, 1);
  --red-900: rgba(127, 29, 29, 1);
  --rose-100: rgba(255, 228, 230, 1);
  --rose-200: rgba(254, 205, 211, 1);
  --rose-300: rgba(253, 164, 175, 1);
  --rose-400: rgba(251, 113, 133, 1);
  --rose-50: rgba(255, 241, 242, 1);
  --rose-500: rgba(244, 63, 94, 1);
  --rose-600: rgba(225, 29, 72, 1);
  --rose-700: rgba(190, 18, 60, 1);
  --rose-800: rgba(159, 18, 57, 1);
  --rose-900: rgba(136, 19, 55, 1);
  --skaff-color-styles1st-seconary-dark: rgba(71, 158, 76, 1);
  --skaff-color-styles1st-secondary-color: rgba(104, 229, 111, 1);
  --skaff-color-styles1st-secondary-light: rgba(126, 239, 132, 1);
  --skaff-color-styles2nd-secondary-color: rgba(143, 239, 255, 1);
  --skaff-color-styles2nd-secondary-dark: rgba(105, 185, 199, 1);
  --skaff-color-styles2nd-secondary-light: rgba(166, 242, 255, 1);
  --skaff-color-stylesprimary-color-default: rgba(120, 60, 249, 0.83);
  --skaff-color-stylesprimary-dark: rgba(86, 43, 178, 1);
  --skaff-color-stylesprimary-light: rgba(151, 102, 255, 0.83);
  --skaff-color-stylesseconary-light: rgba(255, 247, 128, 1);
  --skaff-color-stylessecondary-color: rgba(255, 244, 82, 1);
  --skaff-color-stylessecondary-dark: rgba(196, 187, 47, 1);
  --skaff-color-stylessystem-black: rgba(15, 11, 22, 1);
  --skaff-color-stylessystem-white: rgba(249, 246, 255, 1);
  --sky-100: rgba(224, 242, 254, 1);
  --sky-200: rgba(186, 230, 253, 1);
  --sky-300: rgba(125, 211, 252, 1);
  --sky-400: rgba(56, 189, 248, 1);
  --sky-50: rgba(240, 249, 255, 1);
  --sky-500: rgba(14, 165, 233, 1);
  --sky-600: rgba(2, 132, 199, 1);
  --sky-700: rgba(3, 105, 161, 1);
  --sky-800: rgba(7, 89, 133, 1);
  --sky-900: rgba(12, 74, 110, 1);
  --slate-100: rgba(241, 245, 249, 1);
  --slate-200: rgba(226, 232, 240, 1);
  --slate-300: rgba(203, 213, 225, 1);
  --slate-400: rgba(148, 163, 184, 1);
  --slate-50: rgba(248, 250, 252, 1);
  --slate-500: rgba(100, 116, 139, 1);
  --slate-600: rgba(71, 85, 105, 1);
  --slate-700: rgba(51, 65, 85, 1);
  --slate-800: rgba(30, 41, 59, 1);
  --slate-900: rgba(15, 23, 42, 1);
  --stone-100: rgba(245, 245, 244, 1);
  --stone-200: rgba(231, 229, 228, 1);
  --stone-300: rgba(214, 211, 209, 1);
  --stone-400: rgba(168, 162, 158, 1);
  --stone-50: rgba(250, 250, 249, 1);
  --stone-500: rgba(120, 113, 108, 1);
  --stone-600: rgba(87, 83, 78, 1);
  --stone-700: rgba(68, 64, 60, 1);
  --stone-800: rgba(41, 37, 36, 1);
  --stone-900: rgba(28, 25, 23, 1);
  --teal-100: rgba(204, 251, 241, 1);
  --teal-200: rgba(153, 246, 228, 1);
  --teal-300: rgba(94, 234, 212, 1);
  --teal-400: rgba(45, 212, 191, 1);
  --teal-50: rgba(240, 253, 250, 1);
  --teal-500: rgba(20, 184, 166, 1);
  --teal-600: rgba(13, 148, 136, 1);
  --teal-700: rgba(15, 118, 110, 1);
  --teal-800: rgba(17, 94, 89, 1);
  --teal-900: rgba(19, 78, 74, 1);
  --violet-100: rgba(237, 233, 254, 1);
  --violet-200: rgba(221, 214, 254, 1);
  --violet-300: rgba(196, 181, 253, 1);
  --violet-400: rgba(167, 139, 250, 1);
  --violet-50: rgba(245, 243, 255, 1);
  --violet-500: rgba(139, 92, 246, 1);
  --violet-600: rgba(124, 58, 237, 1);
  --violet-700: rgba(109, 40, 217, 1);
  --violet-800: rgba(91, 33, 182, 1);
  --violet-900: rgba(76, 29, 149, 1);
  --yellow-100: rgba(254, 249, 195, 1);
  --yellow-200: rgba(254, 240, 138, 1);
  --yellow-300: rgba(253, 224, 71, 1);
  --yellow-400: rgba(250, 204, 21, 1);
  --yellow-50: rgba(254, 252, 232, 1);
  --yellow-500: rgba(234, 179, 8, 1);
  --yellow-600: rgba(202, 138, 4, 1);
  --yellow-700: rgba(161, 98, 7, 1);
  --yellow-800: rgba(133, 77, 14, 1);
  --yellow-900: rgba(113, 63, 18, 1);
  --zinc-100: rgba(244, 244, 245, 1);
  --zinc-200: rgba(228, 228, 231, 1);
  --zinc-300: rgba(212, 212, 216, 1);
  --zinc-400: rgba(161, 161, 170, 1);
  --zinc-50: rgba(250, 250, 250, 1);
  --zinc-500: rgba(113, 113, 122, 1);
  --zinc-600: rgba(82, 82, 91, 1);
  --zinc-700: rgba(63, 63, 70, 1);
  --zinc-800: rgba(39, 39, 42, 1);
  --zinc-900: rgba(24, 24, 27, 1);
}
