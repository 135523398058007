.case-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-buttons {
  padding-left: 165px;
}

.center-buttons {
  display: flex;
  gap: 10px;
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.case-screen {
  display: flex;
  flex-direction: row;
  background: var(--Skaff-Color-Styles-System-Gray, #cbd5e1);
}
.screens-sidebar-panel {
  display: flex;
  padding: 32px 0px 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  height: calc(100vh - 188px);
  overflow: auto;
  border-right: 1px solid var(--Skaff-Color-Styles-Disable-Gra---Text, #838080);
}
.screens-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.screens-sidebar .screen-folder {
  display: flex;
  flex-direction: column;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  cursor: pointer;
}

.screens-sidebar .screen-folder-children {
  padding-left: 20px;
}

.screen-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.interview-form-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 10px;

  height: calc(100vh - 188px);
  overflow: auto;
}

.hide-screen {
  display: none !important;
}

.interview-screen-list {
  display: contents;
}

.screen {
  align-self: stretch;
  padding-bottom: 10px;
}

.card-header {
  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  justify-content: center;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* I-16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px; /* 150% */

  /* border: 1px solid var(--Skaff-Color-Styles-Disable-Gra---Text, #838080); */
}

.custom-select {
  display: block;
  width: 100%;
  height: 35px;
  padding: 4px 5px;
  background-color: #f9f6ff;
  background-clip: padding-box;
  border: 0px;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.custom-label {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 12 Pt Paragraph Comment Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

.custom-date {
  display: block;
  width: 100%;
  height: 35px;
  padding: 4px 5px;
  background-color: #f9f6ff;
  background-clip: padding-box;
  border: 0px;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.grid-row {
  display: flex;
}

.one-column > div {
  width: 100%;
}

.two-column > div {
  width: 50%;
  padding-right: 1rem; /* Adjust as needed */
}

.two-column > div:last-child {
  padding-right: 0;
}
