.column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex: 1 0 0; */
}

.column-header-base {
  display: flex;
  height: 60px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-left: 1px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  /* border-right: 1px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2); */
  border-bottom: 1px solid var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  background: var(
    --skaff-color-styles-primary-color-default,
    rgba(120, 60, 249, 0.83)
  );
}

.column-name {
  color: var(--Skaff-Color-Styles-System-White, #f9f6ff);

  /* Bold-18 pt H4 Heading 4 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 133.333% */
}

.header-arrow-img {
  width: 15px;
  height: 15px;
}

.column-items-base {
  display: flex;
  height: 60px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-left: 1px solid
    var(--skaff-color-styles-primary-color-default, rgba(120, 60, 249, 0.83));
  /* border-right: 1px solid
    var(--skaff-color-styles-primary-color-default, rgba(120, 60, 249, 0.83)); */
  border-bottom: 1px solid
    var(--skaff-color-styles-primary-color-default, rgba(120, 60, 249, 0.83));

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.column-contents {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
