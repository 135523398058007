.nav-bar {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 0px 40px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;

  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.nav-bar-left-side {
  display: flex;
  height: 32px;
  padding: 0px 15px;
  align-items: center;
  gap: 20px;
  flex: 1 1 auto;
}

.nav-bar-icon {
  width: 32px;
  height: 32px;
}

.nav-bar-right-side {
  display: flex;
  height: 32px;
  padding: 0px 15px;
  align-items: center;
  gap: 20px;
}
