.box-wrapper {
  display: inline-flex;
  align-items: flex-start;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.input-box {
  display: flex;
  height: 36px;
  padding: 4px 10px;
  align-items: center;
  gap: 20px;

  border-radius: 4px 0px 0px 4px;
  border: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.short {
  width: 154px;
}

.long {
  width: 270px;
}

.search-icon-wrapper {
  display: flex;
  width: 39px;
  height: 36px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;

  border-radius: 0px 4px 4px 0px;
  border-top: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  border-right: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  border-bottom: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.search-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  fill: var(--Skaff-Color-Styles-System-Black, #0f0b16);
}
