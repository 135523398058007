.button-wrapper {
  display: flex;
  align-items: flex-start;

  cursor: pointer;
}

.button-wrapper.selected {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button-component {
  display: flex;
  height: 32px;
  padding: 2px 12px;
  align-items: center;
  gap: 6px;
  background: var(--Skaff-Color-Styles-Disable-Gray---Background, #f0eeee);
}

.button-component:hover {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button-icon {
  width: 15px;
  height: 15px;
}

.button-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
