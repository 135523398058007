.handle {
  height: 10px;
  background: #ddd;
  border-bottom: 5px solid white;
  cursor: move;
  width: 200%;
  margin-left: -50%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  border: 1px solid rgb(204, 204, 204);
  pointer-events: all;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.2);
}
