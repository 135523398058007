.dropdown {
  display: flex;
  height: 32px;
  padding: 2px 12px;
  align-items: center;
  gap: 6px;

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.insert-plus-icon {
  width: 15px;
  height: 15px;
}

.insert-text {
  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.insert-arrow-icon {
  width: 15px;
  height: 15px;
}

.dropdown-menu {
  width: 202px;
  position: absolute;
  z-index: 1000;
}

.polygon {
  width: 33.147px;
  height: 29.443px;
  flex-shrink: 0;
  fill: var(--Skaff-Color-Styles-Gray-One, #dadada);
}

.dropdown-menu-layout {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: relative;
  top: -20px;

  border-radius: 2px;
  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
}

.dropdown-menu-container {
  display: flex;
  width: 190px;
  padding: 6px 4px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
