.top-menu-bar {
  display: flex;
  height: 24px;
  padding: 0px 160px;
  justify-content: center;
  align-items: flex-start;
  /* gap: 366px; */
  flex-shrink: 0;
}

.menu-bar-office-name {
  display: flex;
  height: 24px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  flex: 1 1 auto;
}

.menu-img {
  height: 15px;
  width: 15px;
  fill: var(--Skaff-Color-Styles-System-Black, #0f0b16);
}

.menu-bar-text {
  color: var(
    --skaff-color-styles-primary-color-default,
    rgba(120, 60, 249, 0.83)
  );
  text-align: right;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.menu-bar-right {
  display: flex;
  padding: 0px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.menu-bar-user-menu {
  display: flex;
  height: 24px;
  align-items: center;
  gap: 5px;
  flex: 1 1 auto; /* Allow items to grow */
}

.image-user-name {
  width: 14px;
  height: 15px;
  border-radius: 16.8px;
  background: url(../../icons/menu-bar-user-picture.png) lightgray 0px 0px /
    100% 100% no-repeat;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
    0px 3px 6px 0px rgba(0, 0, 0, 0.23);
}

.menu-bar-help {
  display: flex;
  height: 24px;
  align-items: center;
  gap: 5px;
}

.menu-bar-admin {
  display: flex;
  align-items: center;
  gap: 5px;
}

.menu-bar-logout {
  display: flex;
  height: 24px;
  align-items: center;
  gap: 5px;
}
