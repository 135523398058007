.text-box {
  display: flex;
  height: 32px;
  padding: 4px 10px;
  align-items: center;
  gap: 20px;
}

/* Background variations */
.text-box.light {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}
.text-box.gray {
  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}
.text-box.dark {
  background: #333;
  color: #fff;
}

/* Corner radius variations */
.text-box.sharp {
  border-radius: 0;
}
.text-box.round {
  border-radius: 4px;
}

/* Border visibility */
.text-box.on {
  border: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
}
.text-box.off {
  border: none;
}

.text-name {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: right;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
