.form-label-wrapper {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
}

.form-label-holder {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.form-label {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 12 Pt Paragraph Comment Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

.form-required {
  color: var(--Skaff-Color-Styles-System-Red, var(--System-Red, #ff1212));

  /* 12 Pt Paragraph Comment Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}
