.case-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.case-list-wrapper .content-wrapper {
  display: flex;
  flex: 1 1 auto;
}

.case-list-wrapper .interview-panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* background: var(--Skaff-Color-Styles-System-White, #f9f6ff); */
}

.case-list-wrapper .top-panel {
  display: flex;
  flex-direction: row;
  padding-left: 536px;
  padding-top: 80px;
  gap: 20px;
}

.cases-table-container {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 24px;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.cases-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.cases-table-container thead {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.cases-table-container th {
  position: relative;
  padding: 10px;
  border: 1px solid
    var(--Skaff-Color-Styles-Primary-Light, rgba(151, 102, 255, 0.83));
}

.cases-table-container tbody {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.cases-table-container td {
  padding: 10px;
  text-align: left;

  border: 1px solid var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}

.cases-table-container .table-img {
  vertical-align: middle;
}

.cases-table-container .resizer {
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.cases-table-container .resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .cases-table-container .resizer {
    opacity: 0;
  }

  .cases-table-container *:hover > .resizer {
    opacity: 1;
  }
}

.cases-table-container .pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 10px;
}

.pagination select {
  width: 50px;
  height: 24px;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #eee5ff;
  background: #eee5ff;
  cursor: pointer;
  outline: none;

  font-family: Montserrat;
  font-size: 14px;
}

.pagination-left,
.pagination-right {
  display: flex;
  gap: 5px;
}
