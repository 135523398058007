.templates-table .template-add-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
}

.template-add-modal {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 600px;
  flex-shrink: 0;
}

.template-add-modal-header {
  display: flex;
  justify-content: space-between;

  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.header-close-button {
  display: flex;
  padding: 6px 6px 6px 0px;
  align-items: flex-start;
}

.template-add-modal-contents {
  display: flex;
  padding: 0px 93px 0px 105px;
  flex-direction: column;
  gap: 16px;
}

.template-add-modal-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.template-add-modal-actions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 13px;
  padding-bottom: 38px;
}
