.variable-name-and-type {
  display: flex;
}
.table-container {
  overflow: auto;
  /* display: flex; */
  /* max-width: 1700px; */
  width: 100%;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.table-container thead {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.table-container th {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid
    var(--Skaff-Color-Styles-Primary-Light, rgba(151, 102, 255, 0.83));
  border-left: 1px solid
    var(--Skaff-Color-Styles-Primary-Light, rgba(151, 102, 255, 0.83));
}

.table-container tbody {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.table-container td {
  padding: 10px;
  text-align: left;

  border-left: 1px solid var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}
.table-container th:first-child {
  border-left: none;
}
.table-container td:first-child {
  border-left: none;
}
.table-container .table-img {
  vertical-align: middle;
}

.table-container .resizer {
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.table-container .resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .table-container .resizer {
    opacity: 0;
  }

  .table-container *:hover > .resizer {
    opacity: 1;
  }
}

.table-container .selected-row {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}
