.form-input-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.form-input-container .form-label-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.form-input-container .form-input-label {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 12 Pt Paragraph Comment Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

/* Default styles */
.form-input-container .form-input {
  display: flex;
  height: 35px;
  padding: 4px 10px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  outline: none;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

/* Background variations */
.form-input-container .form-input.light {
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}
.form-input-container .form-input.gray {
  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}
.form-input-container .form-input.dark {
  background: #333;
  color: #fff;
}
.form-input-container .form-input.none {
  background: none;
}

/* Corner radius variations */
.form-input-container .form-input.sharp {
  border-radius: 0;
}
.form-input-container .form-input.round {
  border-radius: 4px;
}

/* Border visibility */
.form-input-container .form-input.on {
  border: 1px solid var(--Skaff-Color-Styles-System-Black, #0f0b16);
}
.form-input-container .form-input.off {
  border: none;
}

/* Use states */
.form-input-container .form-input.default {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
}
.form-input-container .form-input.inactive {
  color: var(--Skaff-Color-Styles-System-Gray, #cbd5e1);
  border: 1px solid var(--Skaff-Color-Styles-System-Gray, #cbd5e1);
  cursor: not-allowed;
}
.form-input-container .form-input.hovering {
  color: var(--skaff-color-styles-primary-color-default, #783cf9);
  border: 1px solid var(--skaff-color-styles-primary-color-default, #783cf9);
}
.form-input-container .form-input.error {
  color: var(--Skaff-Color-Styles-System-Red, var(--System-Red, #ff1212));
  border: 1px solid var(--System-Red, #ff1212);
}
.form-input-container .form-input.selected {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}

.form-error-container {
  /* to-do so that fields dont move when there are errors*/
}

.form-input-error {
  color: var(--Skaff-Color-Styles-System-Red, var(--System-Red, #ff1212));

  font-family: Montserrat;
  font-size: 14px;
  line-height: 28px;
}
