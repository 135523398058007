.menu-button {
  display: flex;
  width: 182px;
  justify-content: center;
  align-items: center;

  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.menu-item {
  display: flex;
  height: 32px;
  padding: 2px 12px;
  align-items: center;
  gap: 6px;

  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.menu-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.menu-icon-right {
  width: 15px;
  height: 15px;
}
