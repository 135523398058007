.right-side-bar-panel {
  display: flex;
  flex-direction: column;
  flex: 0 0 380px;
  /* width: 380px; */
  height: 100%;
  flex-shrink: 0;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
  box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.25);

  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.sidebar-top-area {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 17px;
}

.minimize-icon {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.navigation-bar-container {
  display: flex;
  flex-direction: column;
  padding-top: 17px;
}
.navigation-bar {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
}
