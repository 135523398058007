.top-panel-button {
  display: inline-flex;
  /* height: 32px; */
  padding: 2px 12px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.top-panel-button:hover {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.top-panel-button:active {
  background: var(--Skaff-Color-Styles-Primary-Light-One, #9766ffd4);
}

.top-panel-button-icon {
  width: 15px;
  height: 15px;
}

.top-panel-button-label {
  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.top-panel-button.standard {
  /* nothing for now */
}

.top-panel-button.inactive {
  opacity: 0.5;
  cursor: not-allowed;
}
