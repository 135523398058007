.interview-table {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 24px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  max-height: calc(100vh - 284px);
  overflow-x: auto;
}

.action-button {
  width: 35px;
  height: 35px;
}
