.interview-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.interview-page-wrapper .content-wrapper {
  display: flex;
  flex: 1 1 auto;
}

.interview-page-wrapper .interview-panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* background: var(--Skaff-Color-Styles-System-White, #f9f6ff); */
}

.interview-page-wrapper .top-panel {
  display: flex;
  flex-direction: row;
  padding-left: 536px;
  padding-top: 80px;
  gap: 20px;
}

.interview-modal {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 600px;
  flex-shrink: 0;
}

.interview-modal-header {
  display: flex;
  justify-content: space-between;

  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.header-close-button {
  display: flex;
  padding: 6px 6px 6px 0px;
  align-items: flex-start;
}

.interview-modal-contents {
  display: flex;
  padding: 0px 93px 0px 105px;
  flex-direction: column;
  gap: 16px;
}

.interview-modal-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.interview-modal-error {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--Skaff-Color-Styles-System-Red, var(--System-Red, #ff1212));
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.interview-modal-actions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 13px;
  padding-bottom: 38px;
}
