.case-main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.case-main-wrapper .content-wrapper {
  display: flex;
  flex: 2;
}

.case-main-wrapper .case {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.case .case-header {
  display: flex;
  flex-direction: column;
  padding: 2px 32px 6px 32px;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);

  align-self: stretch;
}

.case .case-name {
  display: flex;
  height: 34px;
  padding-top: 12px;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 24 pt H3 Heading 3 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
}

.case .case-interview-type {
  display: flex;
  height: 34px;
  padding: 4px 0px;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.case .case-nav-bar {
  display: flex;
  padding: 0px 32px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.case .case-nav-icon {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.case .case-nav-icon.selected {
  background-color: #9766ffd4;
}

.case .case-main-component {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-self: stretch;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.case .case-details {
  display: flex;
  flex-direction: column;
  padding: 6px 250px;
}

.case .case-details-header {
  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  align-self: stretch;
  /* width: 300px; */

  background: var(
    --Skaff-Color-Styles-Primary-Light,
    rgba(151, 102, 255, 0.83)
  );
}

.case .case-details-items {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  align-items: flex-start;
  align-self: stretch;

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.case .case-roles {
  display: flex;
  flex-direction: column;
  padding: 6px 250px;
}

.case .case-roles-header {
  display: flex;
  padding: 0px 12px;
  align-items: center;
  justify-content: space-between;
  /* align-self: stretch; */
  /* width: 300px; */

  background: var(
    --Skaff-Color-Styles-Primary-Light,
    rgba(151, 102, 255, 0.83)
  );
}

.case .case-roles-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}

.case .case-role-item {
  display: flex;
  padding: 6px;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;

  border-bottom: 1px solid var(--Skaff-Color-Styles-Disable-Gra---Text, #838080);
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.case .case-role-details {
  display: flex;
}

.case .case-role-type {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  width: 100px;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
}

.case .case-role-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 250px;
}

.case .case-role-add {
  display: flex;
  align-items: flex-end;
  width: 275px;
}

.case .contact-card {
  display: flex;
  padding: 2px 0px;
  align-items: flex-start;
  gap: 4px;
  width: 250px;
  justify-content: space-between;
}

.case .contact-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.case .contact-name {
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 125% */
}

.case .contact-address {
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 150% */
}

.case .contact-phone-email {
  display: flex;
  align-items: center;
  gap: 6px;
}

.case .contact-phone {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 4px;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 150% */
}

.case .contact-email {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 4px;

  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 150% */
}

.case .case-generated-files {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* width: fit-content; */
  max-height: 100%;
  overflow-x: auto;

  padding: 6px 250px;
}
