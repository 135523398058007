.admin-card {
  background-color: var(--skaff-color-stylesprimary-color-default);
  border-radius: 4px;
  box-shadow: 0px 4px 4px #00000040;
  height: 154px;
  overflow: hidden;
  position: relative;
  width: 420px;
}

.admin-card .text-blocks {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 10px;
  position: absolute;
  top: 21px;
}

.admin-card .text-wrapper {
  color: var(--skaff-color-stylessystem-white);
  font-family: var(--bold-32-pt-h2-heading-2-font-family);
  font-size: var(--bold-32-pt-h2-heading-2-font-size);
  font-style: var(--bold-32-pt-h2-heading-2-font-style);
  font-weight: var(--bold-32-pt-h2-heading-2-font-weight);
  letter-spacing: var(--bold-32-pt-h2-heading-2-letter-spacing);
  line-height: var(--bold-32-pt-h2-heading-2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.admin-card .text-wrapper-2 {
  color: var(--skaff-color-stylessystem-white);
  font-family: var(--i-16-pt-paragraph-text-font-family);
  font-size: var(--i-16-pt-paragraph-text-font-size);
  font-style: var(--i-16-pt-paragraph-text-font-style);
  font-weight: var(--i-16-pt-paragraph-text-font-weight);
  letter-spacing: var(--i-16-pt-paragraph-text-letter-spacing);
  line-height: var(--i-16-pt-paragraph-text-line-height);
  position: relative;
  width: 280px;
}

.admin-card .settings {
  align-items: center;
  background-color: var(--skaff-color-stylessystem-white);
  border-radius: 0px 0px 4px 4px;
  display: flex;
  gap: 6px;
  height: 27px;
  left: 0;
  overflow: hidden;
  padding: 6px 168px;
  position: absolute;
  top: 126px;
  width: 420px;
}

.admin-card .text-wrapper-3 {
  color: var(--skaff-color-stylesprimary-dark);
  font-family: var(--16-pt-paragraph-text-font-family);
  font-size: var(--16-pt-paragraph-text-font-size);
  font-style: var(--16-pt-paragraph-text-font-style);
  font-weight: var(--16-pt-paragraph-text-font-weight);
  letter-spacing: var(--16-pt-paragraph-text-letter-spacing);
  line-height: var(--16-pt-paragraph-text-line-height);
  margin-bottom: -3.4px;
  margin-top: -5.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.admin-card .settings-icon {
  position: relative;
  width: 16px;
  height: 16px;
  margin-top: -0.4px;
  margin-bottom: -0.41px;
  margin-right: -6px;
}

.admin-card .icon-wrapper {
  border-radius: 36px/36.13px;
  box-shadow: 0px 6px 8px #00000040;
  height: 72px;
  left: 318px;
  position: absolute;
  top: 27px;
  width: 72px;
  fill: var(--skaff-color-stylessystem-white);
  background-image: url(./../../icons/admin-icon-background.svg);
}

.admin-card .img {
  height: 45px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 45px;
}
