.left-side-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
  /* transition: width 0.3s; */
  z-index: 2;
}

.left-side-bar.minimized {
  width: 51px;
}

.left-side-bar.maximized {
  width: 180px;
}

.left-side-bar .sidebar-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
  align-self: stretch;
}

.left-side-bar .minimize-container {
  display: flex;
  height: 32px;
  padding: 2px 14px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.left-side-bar .minimize-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.sidebar-top-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: sticky;
  top: 0;
}

.button-wrapper {
  display: flex;
  align-items: flex-start;
}

.button-container {
  display: flex;
  /* width: 180px; */
  height: 32px;
  padding: 0px 8px;
  align-items: center;
  gap: 16px;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.button-container-2 {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  gap: 6px;
}

.button-icon {
  width: 15px;
  height: 15px;
}

.button-text {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: sticky;
  bottom: 0;
}
