.screens-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;

  padding-top: 17px;
}

.screen-add-button {
  display: flex;
  height: 32px;
  padding: 2px 12px;
  align-items: center;
  gap: 6px;

  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
}

.screen-add-icon {
  width: 15px;
  height: 15px;
}

.screen-add-text {
  color: var(--Skaff-Color-Styles-Primary-Dark, #562bb2);

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.screens-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.screen-item {
  display: flex;
  align-items: flex-start;
}

.screen-item-container {
  display: flex;
  width: 368px;
  padding-left: 8px;
  align-items: center;
  gap: 12px;

  border-bottom: 1px solid
    var(--skaff-color-styles-primary-color-default, #783cf9);
  border-left: 1px solid
    var(--skaff-color-styles-primary-color-default, #783cf9);
}

.screen-number-container {
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  position: relative;
}

.screen-number-eclipse {
  width: 26px;
  height: 26px;

  fill: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  stroke-width: 1px;
  stroke: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.screen-number-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  width: 18px;
  height: 24px;
  right: 4px;
}

.screen-number {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: center;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.screen-details-container {
  display: flex;
  width: 275px;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;

  border-left: 1px solid
    var(--skaff-color-styles-primary-color-default, #783cf9);
}

.screen-name-container {
  display: flex;
  width: 275px;
  height: 32px;
  padding: 4px 10px;
  align-items: center;
  gap: 20px;

  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}

.screen-name {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: right;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.screen-type-container {
  display: flex;
  width: 275px;
  height: 32px;
  padding: 4px 10px;
  align-items: center;
  gap: 20px;

  background: var(--Skaff-Color-Styles-Select-Text-Field, #dadada);
}

.screen-type {
  color: var(--Skaff-Color-Styles-System-Black, #0f0b16);
  text-align: right;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.screen-actions-container {
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.screen-action-icon {
  width: 15px;
  height: 15px;
}

.screen-modal {
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex-shrink: 0;
}

.screen-modal .screen-modal-header {
  display: flex;
  justify-content: space-between;

  background: var(--Skaff-Color-Styles-Gray-One, #dadada);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.screen-modal .header-close-button {
  display: flex;
  padding: 6px 6px 6px 0px;
  align-items: flex-start;
  gap: 8px;
}

.screen-modal .screen-modal-contents {
  display: flex;
  padding: 0px 93px 0px 105px;
  flex-direction: column;
  gap: 16px;
}

.screen-modal .screen-modal-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.screen-modal .screen-type-details {
  display: flex;
  flex-direction: column;
}

.screen-modal .screen-type-options {
  display: flex;
  padding: 0px 123px 0px 0px;
  gap: 12px;
}

.screen-modal .radio-button-error {
  color: var(--Skaff-Color-Styles-System-Red, var(--System-Red, #ff1212));
  font-family: Montserrat;
  font-size: 14px;
  line-height: 28px;
}

.screen-modal .screen-type-description-text {
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  color: var(--Skaff-Color-Styles-Disable-Gra---Text, #838080);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

.screen-modal .screen-modal-actions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 13px;
  padding: 0px 10px;
  padding-bottom: 36px;
}
