.interview-design-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.interview-design-wrapper .content-wrapper {
  display: flex;
  flex: 2;
}

.interview-design-wrapper .interview-panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* background: var(--Skaff-Color-Styles-System-White, #f9f6ff); */
}

.interview-design-wrapper .interview-panel-divider-width {
  width: 100%;
}

.interview-design-wrapper .interview-form {
  display: flex;
  padding-left: 40px;
  padding-top: 42px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: 200px;
  width: 275px;
}

.interview-design-wrapper .interview-design-top-panel {
  display: flex;
  /* width: 880px; */
  height: 32px;
  padding: 0px 48px 0px 630px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  background: var(--Skaff-Color-Styles-Primary-Light-One, #eee5ff);
}

.interview-design-top-panel .top-panel-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.interview-design-wrapper .interview-design-bottom-panel {
  display: inline-flex;
  align-items: center;
  gap: -15px;
}

.interview-design-bottom-panel .bottom-panel-button-wrapper {
  display: flex;
  align-items: flex-start;
}

.interview-design-bottom-panel .bottom-panel-button-wrapper-1 {
  display: flex;
  align-items: flex-start;
  position: relative;
  left: -15px;
}

.interview-design-bottom-panel .bottom-panel-button-wrapper-2 {
  display: flex;
  align-items: flex-start;
  position: relative;
  left: -30px;
}

.interview-design-bottom-panel .bottom-panel-button-wrapper-3 {
  display: flex;
  align-items: flex-start;
  position: relative;
  left: -45px;
}

.interview-design-wrapper .interview-logic-panel {
  display: flex;
  width: 100%;
  /* height: 595px; */
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  flex-shrink: 0;

  border-left: 1px solid var(--Skaff-Color-Styles-Disable-Gra---Text, #838080);
}

.errors-container {
  padding: 10px;

  /* 16 pt Paragraph Text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
