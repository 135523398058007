.breadcrumb-wrapper {
  display: flex;
  height: 120px;
  /* padding: 15px 977px 10px 40px; */
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;
  background: var(--Skaff-Color-Styles-System-White, #f9f6ff);
  /* top: 24px; */
  /* left: 180px; */
  /* position: absolute; */
}

.breadcrumb-admin-options {
  display: flex;
  padding: 8px 15px;
  align-items: center;
  gap: 5px;
  position: relative;
  left: 40px;
}

.text-block {
  color: var(
    --skaff-color-styles-primary-color-default,
    rgba(120, 60, 249, 0.83)
  );

  /* Bold-18 pt H4 Heading 4 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 133.333% */
}

.arrow-sign-icon {
  width: 7px;
  height: 17px;
  fill: var(--Skaff-Color-Styles-System-Black, #0f0b16);
}

.breadcrumb-subtitle {
  display: flex;
  height: 50px;
  padding: 12px 15px;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;

  position: relative;
  left: 40px;
}

.subtitle-icon {
  width: 35px;
  height: 35px;
}

.subtitle-text-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 0 0 auto;
}

.subtitle-text-block {
  color: var(
    --skaff-color-styles-primary-color-default,
    rgba(120, 60, 249, 0.83)
  );
  text-align: right;

  /* Bold-32 pt H2 Heading 2 */
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 75% */
}
